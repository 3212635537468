@tailwind base;
@tailwind components;
@tailwind utilities;

* {
  border-color: #e5e7eb;
  color-scheme: light;
}

.dark * {
  color-scheme: dark;
  border-color: #ffffff14;
}

@layer utilities {
  .text-balance {
    text-wrap: balance;
  }
}

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }

  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none;
    /* IE and Edge */
    scrollbar-width: none;
    /* Firefox */
  }
}

.load-more-btn {
  border: 1px solid #e02026;
  color: #e02026;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  padding: 5px 20px;
  font-size: 16px;
  line-height: 22px;
  font-weight: 600;
  cursor: pointer;
  background: -webkit-linear-gradient(left, #c00 50%, white 50%);
  background: -moz-linear-gradient(left, #c00 50%, white 50%);
  background: -o-linear-gradient(left, #c00 50%, white 50%);
  background: linear-gradient(to right, #c00 50%, white 50%);
  -webkit-background-size: 200%100%;
  -moz-background-size: 200%100%;
  -o-background-size: 200%100%;
  background-size: 200%100%;
  background-position: right bottom;
  -webkit-transition: all.275s ease-out;
  -moz-transition: all.275s ease-out;
  -o-transition: all.275s ease-out;
  transition: all.275s ease-out;
}

.load-more-btn:hover {
  background-position: left bottom;
  color: white;
}

.login-btn {
  width: 100%;
  height: 50px;
  border-radius: 6px;
  background: #ff0004;
  color: white;
  text-transform: capitalize;
  font-size: 15px;
  font-weight: 500;
  display: flex;
  align-items: center;
  justify-content: center;
}

button:disabled {
  cursor: not-allowed;
}

.short-video-player::part(control-bar bottom) {
  display: none;
}

.short-video-player img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

@layer base {
  input[type="number"]::-webkit-inner-spin-button,
  input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
}